<template>
  <div id="autocomplete-container">
    <v-autocomplete
      attach="#autocomplete-container"
      v-model="localAddress"
      :items="items"
      :search-input.sync="search"
      outlined
      dense
      hide-details
      label="Building address"
      clearable
    />
  </div>
</template>

<script>

// import { customerController } from '@/components/customers/import/controllers'

export default {
  name: 'SearchBuildingByAddress',

  props: ['buildingId', 'address', 'postCode', 'buildingUniqueCode', 'mode'],

  data: () => ({
    worker: window[Symbol.for('map.worker')],
    localAddress: '',
    buildings: [],
    building: null,
    search: null
  }),

  computed: {
    items () {
      return this.buildings.map(building => building.address)
    }
  },

  watch: {
    search (val) {
      val && val !== this.address && this.getVariants(val)
    },

    localAddress (value) {
      this.$emit('update:address', value)
      if (!value) {
        this.$emit('update:buildingId', null)
      } else {
        this.building = this.buildings.find(building => building.address === value)
        if (this.building) {
          const { buildingId, postCode, buildingUniqueCode } = this.building
          this.$emit('update:buildingId', buildingId)
          this.$emit('update:postCode', postCode)
          this.$emit('update:buildingUniqueCode', buildingUniqueCode)
        }
      }
    }
  },

  methods: {
    getVariants (address) {
      if (typeof address !== 'string' || address.length < 3) return this.getBuildingList([])
      this.worker.searchByPartOfAddress(address.toUpperCase(), this.getBuildingList)
    },

    getBuildingList (list) {
      this.buildings = !Array.isArray(list) ? [] : list
      this.building = this.buildings.find(build => build.buildingId === this.buildingId)
    },

    updateBuilding (build) {
      // const { address = '', buildingId = null, postCode = '', buildingUniqueCode = '' } = build || {}
      //
      // this.$emit('update:buildingId', buildingId)
      // this.$emit('update:address', address)
      // this.$emit('update:postCode', postCode)
      // this.$emit('update:buildingUniqueCode', buildingUniqueCode)

      // this.update(build)

      // if (this.mode !== 'export') {
      //   customerController.updateProperty('buildingId', build.buildingId)
      //   customerController.updateProperty('address', build.address)
      //   customerController.updateProperty('postCode', build.postCode)
      // }
    }
  },

  created () {
    this.worker.searchByPartOfAddress(this.address, this.getBuildingList)
  }
}
</script>

<style scoped>

#autocomplete-container {
  position: relative;
}
#autocomplete-container > .v-autocomplete__content.v-menu__content {
  position: relative !important;
  top: 204px !important;
  left: 616px !important;
  max-height: 280px !important;
}
</style>
